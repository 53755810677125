.product-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background-color: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  .product-card:hover {
    transform: scale(1.03);
  }
  
  .product-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .product-details {
    padding: 16px;
    text-align: center;
  }
  
  .product-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
  }
  
  .product-descriptions-title {
    font-weight: bold;
    font-size: 12px;
    color: #333;
    margin-bottom: 0px;
  }

  .product-descriptions {
    font-weight: normal;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }

  .product-price {
    font-size: 26px;
    color: #00a650;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .add-to-cart {
    background-color: #3483fa;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .add-to-cart:hover {
    background-color: #2968c8;
  }